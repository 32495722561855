<template>
  <div class=" font-test" v-show="['zh_tw'].includes(language)">
    <div class="nav-box">
      <div class="top-menu-box">
        <div class="container top-menu">
          <!-- 左边logo -->
          <div class="left-logo">
            <el-image :src="logo" class="logo-img" lazy @click="pageHandle('/home')"></el-image>
          </div>
          <!-- 右边菜单 -->
        </div>
      </div>
    </div>
    <div class="content-box">
      <el-image class="header" :src="header" width="100%" lazy  @load="imgOnload">
        <div slot="placeholder">
          {{$t('加载中')}}<span class="dot">...</span>
        </div>
      </el-image>
      <div class="header-href">
        <el-image :src="activity2" class="activity2" lazy></el-image>
        <!-- 易澜樵溪 -->
        <div class="activity1-2">
          <el-image :src="activity1_2" lazy class="activity1-2"></el-image>
        </div>
        <div class="activity1-1">
          <el-image :src="activity1_1" lazy class="activity1-1"></el-image>
        </div>
        <!-- 台北 -->
        <div class="activity2-2">
          <el-image :src="activity2_2" lazy class="activity2-2"></el-image>
        </div>
        <div class="activity2-1">
          <el-image :src="activity2_1" lazy class="activity2-1"></el-image>
        </div>
        <!-- 北投 -->
        <div class="activity3-2">
          <el-image :src="activity3_2" lazy class="activity3-2"></el-image>
        </div>
        <div class="activity3-1">
          <el-image :src="activity3_1" lazy class="activity3-1"></el-image>
        </div>
      </div>
      <!-- 活动办法 -->
      <div class="header-href">
        <el-image :src="activity" class="activity" lazy></el-image>
        <el-image :src="activity21" class="activity21" lazy></el-image>
        <!-- 任务A -->
        <div class="TaskA">
          <el-image :src="taskA" lazy class="taskA"></el-image>
          <div class="taskA2">
            <el-image :src="taskA2" lazy class="taskA2"></el-image>
            <a href="https://www.facebook.com/travelcontents" class="fb" target="_blank">
              <el-image :src="fb" lazy class="fb"></el-image>
            </a>
          </div>
        </div>
        <!-- 任务B -->
        <div class="TaskB">
          <el-image :src="taskB" lazy class="taskB"></el-image>
          <div class="taskB2">
            <el-image :src="taskB2" lazy class="taskB2"></el-image>
            <a href="https://www.instagram.com/travelcontents.tw/" class="ig" target="_blank">
              <el-image :src="ig" lazy class="ig"></el-image>
            </a>
          </div>
        </div>
      </div>
      <!-- 注意事项 -->
      <div class="header-href">
        <el-image :src="activity3" lazy class="activity3"></el-image>
        <el-image :src="attention" lazy class="attention"></el-image>
      </div>
      <div class="main-box">
        <a href="https://www.facebook.com/travelcontents" class="main-FB" target="_blank">
          <el-image :src="FB_icon" lazy class="FB_icon"></el-image>
        </a>
        <a href="https://www.instagram.com/travelcontents.tw/" class="main-IG" target="_blank">
          <el-image :src="IG_icon" lazy class="IG_icon"></el-image>
        </a>
        <a href="https://www.youtube.com/channel/UCippIPsWWMvz2HZe_Dl0g8Q/featured/" class="main-YT" target="_blank">
          <el-image :src="YT_icon" lazy class="YT_icon"></el-image>
        </a>
      </div>
      <div class="main-box2">
        <a href="https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553" class="apple" target="_blank">
          <el-image :src="apple" lazy class="apple"></el-image>
        </a>
        <a v-if="language != 'zh'" href="https://play.google.com/store/apps/details?id=com.tripellet.app" class="google" target="_blank">
          <el-image :src="google" lazy class="google"></el-image>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showBtn: false,
      logo: require("@/assets/images/customer/MB-home.png"),
      header:require("@/assets/images/japanticket/MB/mb_bg.png"),
      activity :require("@/assets/images/japanticket/MB/activity.png"),
      activity21 :require("@/assets/images/japanticket/MB/activity21.png"),
      activity1_1 :require("@/assets/images/japanticket/MB/activity1-1.png"),
      activity1_2 :require("@/assets/images/japanticket/MB/activity1-2.png"),
      activity2 :require("@/assets/images/japanticket/MB/activity2.png"),
      activity2_1 :require("@/assets/images/japanticket/MB/activity2-1.png"),
      activity2_2 :require("@/assets/images/japanticket/MB/activity2-2.png"),
      activity3 :require("@/assets/images/japanticket/MB/activity3.png"),
      activity3_1 :require("@/assets/images/japanticket/MB/activity3-1.png"),
      activity3_2 :require("@/assets/images/japanticket/MB/activity3-2.png"),
      taskA :require("@/assets/images/japanticket/MB/taskA.png"),
      taskA2 :require("@/assets/images/japanticket/MB/tackA2.png"),
      fb :require("@/assets/images/japanticket/MB/fb.png"),
      taskB :require("@/assets/images/japanticket/MB/taskB.png"),
      taskB2 :require("@/assets/images/japanticket/MB/taskB2.png"),
      ig :require("@/assets/images/japanticket/MB/ig.png"),
      activity3 :require("@/assets/images/japanticket/MB/activity3.png"),
      attention :require("@/assets/images/japanticket/MB/attention.png"),
      FB_icon :require("@/assets/images/japanticket/MB/FB_icon.png"),
      IG_icon :require("@/assets/images/japanticket/MB/IG_icon.png"),
      YT_icon :require("@/assets/images/japanticket/MB/YT_icon.png"),
      apple :require("@/assets/images/japanticket/MB/apple.png"),
      google :require("@/assets/images/japanticket/MB/google.png"),
    }
  },
    mounted(){
    // 进入页面回到顶部
    this.$router.afterEach((to,from,next)=>{
      window.scrollTo(0,0)
    })
  },
  computed: {
    lan() {
      return this.lanList[localStorage.getItem("locale")]
    },
    language() {
      return localStorage.getItem("locale") || 'zh_tw'
    }
  },
  methods: {
    pageHandle(path, params) {
      if (params) {
        this.$router.push({ path, query: { search: JSON.stringify(params) } })
      } else {
        this.$router.push(path)
      }
    },
    openUrl(url) {
      window.open(url)
    },
    imgOnload() {
      this.showBtn = true
      this.$forceUpdate()
    }
  },
}
</script>
<style lang="scss" scoped>
.font-test {
  font-family: "GenJyuuGothic";
}
.container {
  width: 100%;
  margin: 0 auto;
}
.content-box {
  position: relative;
  width: 100%;
  height: auto;
}
.nav-box {
  // width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
}
.top-menu-box {
  background: #fff;
}
.logo-img {
  width: 30px;
  cursor: pointer;
}
.header{
  width: 100%;
  display: block;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}
.activity2{
    position: absolute;
    top: 12.6%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40%;
  }
  .activity1-2{
    position: absolute;
    top: 13.5%;
    left: 58.4%;
    width: 37%;
  }
  .activity1-1{
    position: absolute;
    top: 19.7%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 87.6%;
  }
  .activity2-2{
    position: absolute;
    top: 22%;
    left: 58.3%;
    width: 37%;
  }
  .activity2-1{
    position: absolute;
    top: 28.1%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 79%;
  }
  .activity3-2{
    position: absolute;
    top: 30.4%;
    left: 58.3%;
    width: 37%;
  }
  .activity3-1{
    position: absolute;
    top: 36.6%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 79%;
  }
  .activity{
    position: absolute;
    top: 39.2%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40%;
  }
  .activity21{
    position: absolute;
    top: 41.13%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 58.2%;
  }
  .activity{
    position: absolute;
    top: 39.7%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40%;
  }
  .activity21{
    position: absolute;
    top: 41.13%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 58.2%;
  }
  .TaskA{
    .taskA{
      position: absolute;
      top: 43%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 54.2%;
    }
    .taskA2{
      position: absolute;
      top: 48%;
      left: 47%;
      transform: translate(-50%,-50%);
      width: 86.3%;
    }
    .fb{
      width: 50%;
      position: absolute;
      right: 9%;
      bottom: 28px;
    }
  }
   .TaskB{
    .taskB{
      position: absolute;
      top: 52.3%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 54.2%;
    }
    .taskB2{
      position: absolute;
      top: 56.5%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 92.7%;
    }
    .ig{
      width: 48%;
      position: absolute;
      right: 13.9%;
      bottom: 26px;
    }
  }
  .activity3{
    position: absolute;
    top: 61.7%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40%;
  }
  .attention{
    position: absolute;
    top: 75.2%;
    left: 53%;
    transform: translate(-50%, -50%);
    width: 77%;
  }
  .main-box,.main-box2{
    position: relative;
  }
  .main-FB{
    display: block;
    position: absolute;
    left: 23%;
    bottom: 347px;
    width: 12%;
    height: auto;
    
  }
  .main-IG{
    display: block;
    position: absolute;
    left: 44%;
    bottom: 347px;
    width: 12%;
    height: auto;
    
  }
  .main-YT{
    position: absolute;
    left: 65%;
    bottom: 347px;
    width: 12%;
    height: auto;
  }
  .apple{
      position: absolute;
      left: 14.5%;
      bottom: 6vh;
      width: 118px;
      height: auto;
      
  }
  .google{
      position: absolute;
      left: 40.5%;
      bottom: 6vh;
      width: 118px;
      height: auto;
      
  }
@media screen and (min-width: 321px) and (max-width: 375px){
  .activity2{
    position: absolute;
    top: 12.6%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40%;
  }
  .activity1-2{
    position: absolute;
    top: 13.5%;
    left: 58.4%;
    width: 37%;
  }
  .activity1-1{
    position: absolute;
    top: 19.7%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 87.6%;
  }
  .activity2-2{
    position: absolute;
    top: 22%;
    left: 58.3%;
    width: 37%;
  }
  .activity2-1{
    position: absolute;
    top: 28.1%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 79%;
  }
  .activity3-2{
    position: absolute;
    top: 30.4%;
    left: 58.3%;
    width: 37%;
  }
  .activity3-1{
    position: absolute;
    top: 36.6%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 79%;
  }
  .activity{
    position: absolute;
    top: 39.7%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40%;
  }
  .activity21{
    position: absolute;
    top: 41.13%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 58.2%;
  }
  .TaskA{
    .taskA{
      position: absolute;
      top: 43%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 54.2%;
    }
    .taskA2{
      position: absolute;
      top: 47.6%;
      left: 47%;
      transform: translate(-50%,-50%);
      width: 86.3%;
    }
    .fb{
      width: 50%;
      position: absolute;
      right: 9%;
      bottom: 26px;
    }
  }
   .TaskB{
    .taskB{
      position: absolute;
      top: 52.3%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 54.2%;
    }
    .taskB2{
      position: absolute;
      top: 56.5%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 92.7%;
    }
    .ig{
      width: 48%;
      position: absolute;
      right: 13.9%;
      bottom: 26px;
    }
  }
  .activity3{
    position: absolute;
    top: 61.7%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40%;
  }
  .attention{
    position: absolute;
    top: 75.2%;
    left: 53%;
    transform: translate(-50%, -50%);
    width: 77%;
  }
  .main-box,.main-box2{
    position: relative;
  }
  .main-FB{
    display: block;
    position: absolute;
    left: 23%;
    bottom: 328px;
    width: 12%;
    height: auto;
    
  }
  .main-IG{
    display: block;
    position: absolute;
    left: 44%;
    bottom: 328px;
    width: 12%;
    height: auto;
    
  }
  .main-YT{
    position: absolute;
    left: 65%;
    bottom: 328px;
    width: 12%;
    height: auto;
    

  }
  .apple{
      position: absolute;
      left: 11.5%;
      bottom: 48px;
      width: 118px;
      height: auto;
      
  }
  .google{
      position: absolute;
      left: 38.5%;
      bottom: 48px;
      width: 118px;
      height: auto;
      
  }
}
@media screen and (max-width: 320px){
  .activity2{
    position: absolute;
    top: 12.6%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40%;
  }
  .activity1-2{
    position: absolute;
    top: 13.5%;
    left: 58.4%;
    width: 37%;
  }
  .activity1-1{
    position: absolute;
    top: 19.7%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 87.6%;
  }
  .activity2-2{
    position: absolute;
    top: 22%;
    left: 58.3%;
    width: 37%;
  }
  .activity2-1{
    position: absolute;
    top: 28.1%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 79%;
  }
  .activity3-2{
    position: absolute;
    top: 30.4%;
    left: 58.3%;
    width: 37%;
  }
  .activity3-1{
    position: absolute;
    top: 36.6%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 79%;
  }
  .activity{
    position: absolute;
    top: 39.7%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40%;
  }
  .activity21{
    position: absolute;
    top: 41.13%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 58.2%;
  }
  .TaskA{
    .taskA{
      position: absolute;
      top: 43%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 54.2%;
    }
    .taskA2{
      position: absolute;
      top: 48%;
      left: 47%;
      transform: translate(-50%,-50%);
      width: 86.3%;
    }
    .fb{
      width: 50%;
      position: absolute;
      right: 9%;
      bottom: 22px;
    }
  }
   .TaskB{
    .taskB{
      position: absolute;
      top: 52.3%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 54.2%;
    }
    .taskB2{
      position: absolute;
      top: 56.5%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 92.7%;
    }
    .ig{
      width: 48%;
      position: absolute;
      right: 13.9%;
      bottom: 24px;
    }
  }
  .activity3{
    position: absolute;
    top: 61.7%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40%;
  }
  .attention{
    position: absolute;
    top: 75.2%;
    left: 53%;
    transform: translate(-50%, -50%);
    width: 77%;
  }
  .main-box,.main-box2{
    position: relative;
  }
  .main-FB{
    display: block;
    position: absolute;
    left: 23%;
    bottom: 293px;
    width: 12%;
    height: auto;
    
  }
  .main-IG{
    display: block;
    position: absolute;
    left: 44%;
    bottom: 293px;
    width: 12%;
    height: auto;
    
  }
  .main-YT{
    position: absolute;
    left: 65%;
    bottom: 293px;
    width: 12%;
    height: auto;
  }
  .apple{
      position: absolute;
      left: 11.5%;
      bottom: 40px;
      width: 105px;
      height: auto;
      
  }
  .google{
      position: absolute;
      left: 38.5%;
      bottom: 40px;
      width: 105px;
      height: auto;
      
  }
}
@media screen and (min-width: 394px) and (max-width: 428px){
  .activity2{
    position: absolute;
    top: 12.6%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40%;
  }
  .activity1-2{
    position: absolute;
    top: 13.5%;
    left: 58.4%;
    width: 37%;
  }
  .activity1-1{
    position: absolute;
    top: 19.7%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 87.6%;
  }
  .activity2-2{
    position: absolute;
    top: 22%;
    left: 58.3%;
    width: 37%;
  }
  .activity2-1{
    position: absolute;
    top: 28.1%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 79%;
  }
  .activity3-2{
    position: absolute;
    top: 30.4%;
    left: 58.3%;
    width: 37%;
  }
  .activity3-1{
    position: absolute;
    top: 36.6%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 79%;
  }
  .activity{
    position: absolute;
    top: 39.7%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40%;
  }
  .activity21{
    position: absolute;
    top: 41.13%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 58.2%;
  }
  .TaskA{
    .taskA{
      position: absolute;
      top: 43%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 54.2%;
    }
    .taskA2{
      position: absolute;
      top: 48%;
      left: 47%;
      transform: translate(-50%,-50%);
      width: 86.3%;
    }
    .fb{
      width: 50%;
      position: absolute;
      right: 9%;
      bottom: 28px;
    }
  }
   .TaskB{
    .taskB{
      position: absolute;
      top: 52.3%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 54.2%;
    }
    .taskB2{
      position: absolute;
      top: 56.5%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 92.7%;
    }
    .ig{
      width: 48%;
      position: absolute;
      right: 13.9%;
      bottom: 26px;
    }
  }
  .activity3{
    position: absolute;
    top: 61.7%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40%;
  }
  .attention{
    position: absolute;
    top: 75.2%;
    left: 53%;
    transform: translate(-50%, -50%);
    width: 77%;
  }
  .main-box,.main-box2{
    position: relative;
  }
  .main-FB{
    display: block;
    position: absolute;
    left: 23%;
    bottom: 376px;
    width: 12%;
    height: auto;
    
  }
  .main-IG{
    display: block;
    position: absolute;
    left: 44%;
    bottom: 376px;
    width: 12%;
    height: auto;
    
  }
  .main-YT{
    position: absolute;
    left: 65%;
    bottom: 376px;
    width: 12%;
    height: auto;
  }
  .apple{
      position: absolute;
      left: 14.5%;
      bottom: 6vh;
      width: 118px;
      height: auto;
      
  }
  .google{
      position: absolute;
      left: 40.5%;
      bottom: 6vh;
      width: 118px;
      height: auto;
      
  }
}

</style>
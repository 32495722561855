<template>
  <div class=" font-test" v-show="['zh_tw'].includes(language)">
    <div class="nav-box">
      <div class="top-menu-box">
        <div class="container top-menu">
          <!-- 左边logo -->
          <div class="left-logo">
            <el-image :src="logo" class="logo-img" lazy @click="pageHandle('/home')"></el-image>
          </div>
          <!-- 右边菜单 -->
        </div>
      </div>
    </div>
    <div class="content-box">
      <el-image class="header" :src="header" width="100%" lazy  @load="imgOnload">
        <div slot="placeholder">
          {{$t('加载中')}}<span class="dot">...</span>
        </div>
      </el-image>
      <div class="header-href" v-if="showBtn">
        <el-image :src="activity2" class="activity2" lazy></el-image>
        <!-- 易澜樵溪 -->
        <div class="activity1-1">
          <el-image :src="activity11" lazy class="activity1-1"></el-image>
        </div>
        <!-- 台北 -->
        <div class="activity2-1">
          <el-image :src="activity12" lazy class="activity2-1"></el-image>
        </div>
        <!-- 北投 -->
        <div class="activity3-1">
          <el-image :src="activity13" lazy class="activity3-1"></el-image>
        </div>
      </div>
      <!-- 活动办法 -->
      <div class="header-href" v-if="showBtn">
        <el-image :src="activity" class="activity" lazy></el-image>
        <el-image :src="activity21" class="activity21" lazy></el-image>
        <!-- 任务A -->
        <div class="TaskA">
          <el-image :src="taskA" lazy class="taskA"></el-image>
          <div class="taskA2">
            <el-image :src="taskA2" lazy class="taskA2"></el-image>
            <a href="https://www.facebook.com/travelcontents" class="fb" target="_blank">
              <el-image :src="fb" lazy class="fb"></el-image>
            </a>
          </div>
        </div>
        <!-- 任务B -->
        <div class="TaskB">
          <el-image :src="taskB" lazy class="taskB"></el-image>
          <div class="taskB2">
            <el-image :src="taskB2" lazy class="taskB2"></el-image>
            <a href="https://www.instagram.com/travelcontents.tw/" class="ig" target="_blank">
              <el-image :src="ig" lazy class="ig"></el-image>
            </a>
          </div>
        </div>
      </div>
      <!-- 注意事项 -->
      <div class="header-href" v-if="showBtn">
        <el-image :src="activity3" lazy class="activity3"></el-image>
        <el-image :src="attention" lazy class="attention"></el-image>
      </div>
      <div class="main-box" v-if="showBtn">
        <a href="https://www.facebook.com/travelcontents" class="main-FB" target="_blank">
          <el-image :src="FB_icon" lazy class="FB_icon"></el-image>
        </a>
        <a href="https://www.instagram.com/travelcontents.tw/" class="main-IG" target="_blank">
          <el-image :src="IG_icon" lazy class="IG_icon"></el-image>
        </a>
        <a href="https://www.youtube.com/channel/UCippIPsWWMvz2HZe_Dl0g8Q/featured/" class="main-YT" target="_blank">
          <el-image :src="YT_icon" lazy class="YT_icon"></el-image>
        </a>
      </div>
      <div class="main-box2" v-if="showBtn">
        <a href="https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553" class="apple" target="_blank">
          <el-image :src="apple" lazy class="apple"></el-image>
        </a>
        <a v-if="language != 'zh'" href="https://play.google.com/store/apps/details?id=com.tripellet.app" class="google" target="_blank">
          <el-image :src="google" lazy class="google"></el-image>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showBtn: false,
      logo: require("@/assets/images/customer/MB-home.png"),
      header:require("@/assets/images/japanticket/TA/ta_bg.jpg"),
      activity :require("@/assets/images/japanticket/TA/activity.png"),
      activity11 :require("@/assets/images/japanticket/TA/acticity11.png"),
      activity12 :require("@/assets/images/japanticket/TA/activity12.png"),
      activity21 :require("@/assets/images/japanticket/TA/activity21.png"),
      activity2 :require("@/assets/images/japanticket/TA/activity2.png"),
      activity13 :require("@/assets/images/japanticket/TA/activity13.png"),
      taskA :require("@/assets/images/japanticket/TA/taskA.png"),
      taskA2 :require("@/assets/images/japanticket/TA/taskA2.png"),
      fb :require("@/assets/images/japanticket/TA/fb.png"),
      taskB :require("@/assets/images/japanticket/TA/taskB.png"),
      taskB2 :require("@/assets/images/japanticket/TA/taskB2.png"),
      ig :require("@/assets/images/japanticket/TA/ig.png"),
      activity3 :require("@/assets/images/japanticket/TA/activity3.png"),
      attention :require("@/assets/images/japanticket/TA/attention.png"),
      FB_icon :require("@/assets/images/japanticket/TA/FB_icon.png"),
      IG_icon :require("@/assets/images/japanticket/TA/IG_icon.png"),
      YT_icon :require("@/assets/images/japanticket/TA/YT_icon.png"),
      apple :require("@/assets/images/japanticket/TA/apple.png"),
      google :require("@/assets/images/japanticket/TA/google.png"),
    }
  },
    mounted(){
    // 进入页面回到顶部
    this.$router.afterEach((to,from,next)=>{
      window.scrollTo(0,0)
    })
  },
  computed: {
    lan() {
      return this.lanList[localStorage.getItem("locale")]
    },
    language() {
      return localStorage.getItem("locale") || 'zh_tw'
    }
  },
  methods: {
    pageHandle(path, params) {
      if (params) {
        this.$router.push({ path, query: { search: JSON.stringify(params) } })
      } else {
        this.$router.push(path)
      }
    },
    openUrl(url) {
      window.open(url)
    },
    imgOnload() {
      this.showBtn = true
      this.$forceUpdate()
    }
  },
}
</script>
<style lang="scss" scoped>
.font-test {
  font-family: "GenJyuuGothic";
}
.container {
  width: 100%;
  margin: 0 auto;
}
.content-box {
  position: relative;
  width: 100%;
  height: auto;
}
.nav-box {
  // width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
}
.top-menu-box {
  background: #fff;
}
.logo-img {
  width: 30px;
  cursor: pointer;
}
.header{
  width: 100%;
  display: block;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

  .activity2{
    position: absolute;
    top: 17%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 20.7%;
  }
  .activity1-1{
    position: absolute;
    top: 19.4%;
    left: 61%;
    transform: translate(-50%,-50%);
    width: 68.8%;
  }
  .activity2-1{
    position: absolute;
    top: 23%;
    left: 61%;
    transform: translate(-50%,-50%);
    width: 68.2%;
  }
  .activity3-1{
    position: absolute;
    top: 26.7%;
    left: 61%;
    transform: translate(-50%,-50%);
    width: 68.2%;
  }
  .activity{
    position: -webkit-absolute;
    position: absolute;
    top: 32%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 21.4%;
  }
  .activity21{
    position: absolute;
    top: 33.13%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 32%;
  }
  .TaskA{
    .taskA{
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 42%;
    }
    .taskA2{
      position: absolute;
      top: 42%;
      left: 48%;
      transform: translate(-50%, -50%);
      width: 90.8%;
    }
    .fb{
      width: 38%;
      position: absolute;
      right: 8%;
      bottom: 46px;
    }
  }
   .TaskB{
    .taskB{
      position: absolute;
      top: 49.3%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 42%;
    }
    .taskB2{
      position: absolute;
      top: 55.5%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 90.8%;
    }
    .ig{
      width: 38%;
      position: absolute;
      right: 37%;
      bottom: 46px;
    }
  }
  .activity3{
    position: absolute;
    top: 62.5%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 20.7%;
  }
  .attention{
    position: absolute;
    top: 72.5%;
    left: 51%;
    transform: translate(-50%, -50%);
    width: 83%;
  }
  .main-box,.main-box2{
    position: relative;
  }
  .main-FB{
    display: block;
    position: absolute;
    left: 31%;
    bottom: 68vw;
    width: 8.8vw;
    height: auto;
  }
  .main-IG{
    display: block;
    position: absolute;
    left: 46%;
    bottom: 68vw;
    width: 8.8vw;
    height: auto;
  }
  .main-YT{
    position: absolute;
    left: 60%;
    bottom: 68vw;
    width: 8.8vw;
    height: auto;

  }
  .apple{
      position: -webkit-absolute;
      position: absolute;
      left: 9vw;
      bottom: 6vh;
      width: 30vw;
      height: auto;
  }
  .google{
     position: -webkit-absolute;
      position: absolute;
      left: 26vw;
      bottom: 6vh;
      width: 30vw;
      height: auto;
  }
</style>
<template>
  <div class="font-test">
    <div class="nav-box">
      <div class="top-menu-box" style="width: 1000px; margin: 0 auto">
        <div class="container top-menu">
          <!-- 左边logo -->
          <div class="left-logo">
            <el-image :src="logo" class="logo-img" lazy @click="pageHandle('/home')"></el-image>
          </div>
          <!-- 右边菜单 -->
        </div>
      </div>
    </div>
    <div class="content-box">
        <el-image :src="header" class="header" lazy @load="imgOnload">
          <div slot="placeholder" v-if="showBtn" class="loading">
            {{ $t("加载中") }}<span class="dot">...</span>
          </div>
        </el-image>
        <!-- 活动奖项 -->
      <div class="header-href">
        <el-image :src="activity" lazy class="acticity"></el-image>
      </div>
      <!-- 活动办法 -->
      <div class="header-href2">
        <el-image :src="activity11" lazy class="acticity11"></el-image>
        <el-image :src="activity12" lazy class="acticity12"></el-image>
        <el-image :src="activity13" lazy class="acticity13"></el-image>
      </div>
      <!-- 任务A -->
      <div class="Activities-to">
        <el-image :src="activity2" lazy class="activity2"></el-image>
        <el-image :src="activity21" lazy class="activity21"></el-image>
      </div>
      <div class="Task" v-if="showBtn">
        <el-image :src="taskA" lazy class="taskA" ></el-image>
        <div class="TaskA">
          <el-image :src="taskA2" lazy class="taskA2"></el-image>
          <a href="https://www.facebook.com/travelcontents" class="fb" target="_blank">
            <el-image :src="fb" lazy></el-image>
          </a>
        </div>
      </div>
      <!-- 任务B -->
      <div class="TaskB" v-if="showBtn">
        <el-image :src="taskB" lazy class="taskB"></el-image>
        <div class="Taskb">
          <el-image :src="taskB2" lazy class="taskB2"></el-image>
          <a href="https://www.instagram.com/travelcontents.tw/" class="ig" target="_blank">
            <el-image :src="ig" lazy></el-image>
          </a>
        </div>
      </div>
      <!-- 注意事项 -->
      <div class="Attention" v-if="showBtn">
        <el-image :src="activity3" lazy class="activity3"></el-image>
        <el-image :src="attention" lazy class="attention"></el-image>
      </div>
      <div class="main-box" v-if="showBtn">
        <a href="https://www.facebook.com/travelcontents" class="main-FB" target="_blank">
          <el-image :src="FB_icon" lazy></el-image>
        </a>
        <a href="https://www.instagram.com/travelcontents.tw/" class="main-IG" target="_blank">
          <el-image :src="IG_icon" lazy></el-image>
        </a>
        <a href="https://www.youtube.com/channel/UCippIPsWWMvz2HZe_Dl0g8Q/featured/" class="main-YT" target="_blank">
          <el-image :src="YT_icon" lazy></el-image>
        </a>
      </div>
      <!-- 二维码 -->
      <div class="footer" v-if="showBtn">
        <a href="https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553" class="apple" target="_blank">
          <el-image :src="apple" lazy></el-image>
        </a>
        <a v-if="language != 'zh'" href="https://play.google.com/store/apps/details?id=com.tripellet.app" class="google" target="_blank">
          <el-image :src="google" lazy></el-image>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showBtn: false,
      logo: require("@/assets/images/customer/PC-home.png"),
      header: require("@/assets/images/japanticket/PC/pc_bg.jpg"),
      activity :require("@/assets/images/japanticket/PC/activity1.png"),
      activity11 :require("@/assets/images/japanticket/PC/acticity11.png"),
      activity12 :require("@/assets/images/japanticket/PC/activity12.png"),
      activity13 :require("@/assets/images/japanticket/PC/activity13.png"),
      activity2 :require("@/assets/images/japanticket/PC/activity2.png"),
      activity21 :require("@/assets/images/japanticket/PC/activity21.png"),
      taskA :require("@/assets/images/japanticket/PC/taskA.png"),
      taskA2 :require("@/assets/images/japanticket/PC/taskA2.png"),
      fb :require("@/assets/images/japanticket/PC/fb.png"),
      taskB :require("@/assets/images/japanticket/PC/taskB.png"),
      taskB2 :require("@/assets/images/japanticket/PC/taskB2.png"),
      ig :require("@/assets/images/japanticket/PC/ig.png"),
      activity3 :require("@/assets/images/japanticket/PC/activity3.png"),
      attention :require("@/assets/images/japanticket/PC/attention.png"),
      FB_icon :require("@/assets/images/japanticket/PC/FB_icon.png"),
      IG_icon :require("@/assets/images/japanticket/PC/IG_icon.png"),
      YT_icon :require("@/assets/images/japanticket/PC/YT_icon.png"),
      apple :require("@/assets/images/japanticket/PC/apple.png"),
      google :require("@/assets/images/japanticket/PC/google.png"),
    };
  },
  computed: {
    language() {
      return localStorage.getItem("locale") || "zh_tw";
    },
  },
  created(){
    this.imgOnload
  },
  mounted() {
    // 进入页面回到顶部
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0);
    });
  },
  methods: {
    pageHandle(path, params) {
      if (params) {
        this.$router.push({ path, query: { search: JSON.stringify(params) } });
      } else {
        this.$router.push(path);
      }
    },
    imgOnload() {
      this.showBtn = true;
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
.font-test {
  font-family: "GenJyuuGothic";
  }
.app-container {
  width: 90%;
  margin: 0 auto;
}
.content-box {
  position: relative;
  width: 100%;
  height: auto;
}

.button-box {
  position: absolute;
  top: 62.2%;
  left: 52.8%;
  z-index: 999;
  .el-image {
    width: 19%;
    margin: 0 5px;
  }
}
.nav-box {
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
}
.top-menu-box {
  background: #fff;
}
.logo-img {
  width: 30px;
  cursor: pointer;
}
.header {
  width: 100%;
  display: block;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
.loading{
  font-size: 80px;
  color: #ff8b21;
  margin: 20% auto;
  text-align: center;
}
.header-href{
  position: absolute;
  top: 17.8%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  .acticity{
    width: 50.5%;
    height: auto;
  }
}
.header-href2{
  position: absolute;
  top: 25.8%;
  left: 63.9%;
  transform: translate(-50%,-50%);
  .acticity11{
    width: 74.8%;
    height: auto;
  }
  .acticity12{
    width: 74.8%;
    height: auto;
    margin: 10% 0;
  }
  .acticity13{
    width: 74.8%;
    height: auto;
  }
}
.Activities-to{
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  .activity2{
    width: 34%;
    height: auto;
    margin-bottom: 7%;
  }
  .activity21{
    width: 50%;
    height: auto;
  }
}
.Task{
  position: absolute;
  top: 43.5%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  margin-top: 7%;
  .taskA{
    width: 50%;
    height: auto;
    margin-bottom: 9%;
  }
  .taskA2{
    margin-right: 10%;
    position: relative;
  }
  .fb{
    display: block;
    width: 13%;
    position: absolute;
    right: 11%;
    top: 22%;
  }
}
.TaskB{
  position: absolute;
  top: 57.5%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  margin-top: 7%;
  .taskB{
    width: 50%;
    height: auto;
    margin-bottom: 9%;
  }
  .taskB2{
    margin-right: 10%;
    position: relative;
  }
  .ig{
    display: block;
    width: 13%;
    position: absolute;
    right: 57%;
    top: 29%;
  }
}
.Attention{
  position: absolute;
  top: 74.1%;
  left: 51%;
  transform: translate(-50%,-50%);
  text-align: center;
  margin-top: 7%;
  .activity3{
    width: 22.5%;
    height: auto;
    margin-bottom: 6%;
  }
  .attention{
    width: 90%;
  }
}
.main-box{
  position: absolute;
  bottom: 9%;
  left: 42%;
  display: flex;
  .main-FB {
    width: 14%;
    height: auto;
    display: block;
  }
  .main-IG {
    width: 14%;
    height: auto;
    display: block;
    margin: 0 50px;
  }
  .main-YT {
    width: 14%;
    height: auto;
    display: block;
  }
}
.footer{
  position: absolute;
  bottom: 2.4%;
  left: 43%;
  display: flex;
   .apple {
    width: 24.5%;
    height: 50px;
    margin-right: 20px;
  }
  .google { 
    width: 24.5%;
    height: 50px;
  }
}
@media screen and (min-width: 1441px) and (max-width: 1920px) {
  .header-href{
    position: absolute;
    top: 17.8%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .acticity{
      width: 50.5%;
      height: auto;
    }
  }
  .header-href2{
    position: absolute;
    top: 25.8%;
    left: 63.9%;
    transform: translate(-50%,-50%);
    .acticity11{
      width: 74.8%;
      height: auto;
    }
    .acticity12{
      width: 74.8%;
      height: auto;
      margin: 10% 0;
    }
    .acticity13{
      width: 74.8%;
      height: auto;
    }
  }
  .Activities-to{
    position: absolute;
    top: 37%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .activity2{
      width: 11vw;
      height: auto;
      margin-bottom: 7%;
    }
    .activity21{
      width: 17vw;
      height: auto;
    }
  }
  .Task{
    position: absolute;
    top: 43.5%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    margin-top: 7%;
    .taskA{
      width: 50%;
      height: auto;
      margin-bottom: 9%;
    }
    .taskA2{
      margin-right: 10%;
      position: relative;
    }
    .fb{
      display: block;
      width: 13%;
      position: absolute;
      right: 11%;
      top: 22%;
    }
  }
  .TaskB{
    position: absolute;
    top: 57.5%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    margin-top: 7%;
    .taskB{
      width: 50%;
      height: auto;
      margin-bottom: 9%;
    }
    .taskB2{
      margin-right: 10%;
      position: relative;
    }
    .ig{
      display: block;
      width: 13%;
      position: absolute;
      right: 57%;
      top: 29%;
      // -moz-box-shadow:0px 3px 2px #333333; 
      // -webkit-box-shadow:0px 3px 2px #333333;
      //  box-shadow:0px 3px 2px #333333;
    }
  }
  .Attention{
    position: absolute;
    top: 74.1%;
    left: 51%;
    transform: translate(-50%,-50%);
    text-align: center;
    margin-top: 7%;
    .activity3{
      width: 22.5%;
      height: auto;
      margin-bottom: 6%;
    }
    .attention{
      width: 90%;
    }
  }
  .main-box{
    position: absolute;
    bottom: 8.4%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: space-evenly;
    .main-FB {
      width: 16%;
      height: auto;
      display: block;
    }
    .main-IG {
      width: 16%;
      height: auto;
      display: block;
      margin: 0;
    }
    .main-YT {
      width: 16%;
      height: auto;
      display: block;
    }
  }
  .footer{
    position: absolute;
    bottom: 2.3%;
    left: 43%;
    display: flex;
    .apple {
      width: 9vw;
      height: auto;
      margin-right: 15px;
    }
    .google { 
      width: 9vw;
      height: 50px;
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .content-box {
    position: relative;
    width: 100%;
    height: auto;
  }
  .header-href{
    position: absolute;
    top: 17.8%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .acticity{
      width: 37.5%;
      height: auto;
    }
  }
  .header-href2{
    position: absolute;
    top: 25.8%;
    left: 63.9%;
    transform: translate(-50%,-50%);
    .acticity11{
      width: 74.8%;
      height: auto;
    }
    .acticity12{
      width: 74.8%;
      height: auto;
      margin: 10% 0;
    }
    .acticity13{
      width: 74.8%;
      height: auto;
    }
  }
  .Activities-to{
    position: absolute;
    top: 36.5%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .activity2{
      width: 17.8vw;
      height: auto;
      margin-bottom: 15%;
    }
    .activity21{
      width: 16.4vw;
      height: auto;
    }
  }
  .Task{
    position: absolute;
    top: 43.5%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    margin-top: 7%;
    .taskA{
      width: 50%;
      height: auto;
      margin-bottom: 9%;
    }
    .taskA2{
      margin-right: 10%;
      position: relative;
    }
    .fb{
      display: block;
      width: 13%;
      position: absolute;
      right: 11%;
      top: 22%;
    }
  }
  .TaskB{
    position: absolute;
    top: 57.5%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    margin-top: 7%;
    .taskB{
      width: 50%;
      height: auto;
      margin-bottom: 9%;
    }
    .taskB2{
      margin-right: 10%;
      position: relative;
    }
    .ig{
      display: block;
      width: 13%;
      position: absolute;
      right: 50%;
      top: 29%;
      // -moz-box-shadow:0px 3px 2px #333333; 
      // -webkit-box-shadow:0px 3px 2px #333333;
      //  box-shadow:0px 3px 2px #333333;
    }
  }
  .Attention{
    position: absolute;
    top: 74.1%;
    left: 51%;
    transform: translate(-50%,-50%);
    text-align: center;
    margin-top: 7%;
    .activity3{
      width: 22.5%;
      height: auto;
      margin-bottom: 6%;
    }
    .attention{
      width: 90%;
    }
  }
  .main-box{
    position: absolute;
    bottom: 7.9%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    .main-FB {
      width: 5.1vw;
      height: auto;
      display: block;
    }
    .main-IG {
      width: 5.1vw;
      height: auto;
      display: block;
      margin: 0 3vw;
    }
    .main-YT {
      width: 5.1vw;
      height: auto;
      display: block;
    }
  }
  .footer{
    position: absolute;
    bottom: 6.3vw;
    left: 43%;
    display: flex;
    .apple {
      width:9vw;
      height: auto;
      margin-right: 10px;
    }
    .google { 
      width:9vw;
      height: auto;
    }
  }
}
@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .content-box {
    position: relative;
    width: 100%;
    height: auto;
  }
  
  .Activities-to{
    position: absolute;
    top: 36.67%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .activity2{
      width: 17vw;
      height: auto;
      margin-bottom: 13%;
    }
    .activity21{
      width: 17vw;
      height: auto;
    }
  }
  .main-box{
    position: absolute;
    bottom: 24vw;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: space-evenly;
    .main-FB {
      width: 4vw !important;
      height: auto;
      display: block;
    }
    .main-IG {
      width: 4vw !important;
      height: auto;
      display: block;
      margin: 0 30px!important;
    }
    .main-YT {
      width: 4vw !important;
      height: auto;
      display: block;
    }
  }
  .footer{
    position: relative;
    bottom:0;
    left: 0;
    display: flex;
    .apple {
      position: absolute;
      left: 43%;
      bottom: 6.2vw;
      width: 9vw !important;
      height: auto;
      margin-right: 0;
    }
    .google {
      position: absolute;
      left: 53%;
      bottom: 6.2vw;
      width: 9vw !important;
      height: auto;
    }
  }
}
@media screen and (min-width: 1930px) {
  .content-box {
    position: relative;
    width: 100%;
    height: auto;
  }
  .main-box {
    position: relative;
  }
  .header-href{
    position: absolute;
    top: 17.8%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .acticity{
      width: 12vw;
      height: auto;
    }
  }
  .Activities-to{
    position: absolute;
    top: 36.8%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .activity2{
      width: 70%;
      height: auto;
      margin-bottom: 4vh;
    }
    .activity21{
      width: 17vw;
      height: auto;
    }
  }
  .main-box{
    position: absolute;
    bottom: 22vw;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: space-evenly;
    .main-FB {
      width: 4vw !important;
      height: auto;
      display: block;
    }
    .main-IG {
      width: 4vw !important;
      height: auto;
      display: block;
      margin: 0 50px!important;
    }
    .main-YT {
      width: 4vw !important;
      height: auto;
      display: block;
    }
  }
  .footer{
    position: absolute;
    left: 43%;
    bottom: 6.5vw;
    .apple ,.google{
      width: 8.3vw !important;
      height: auto;
    }
  }
}
</style>
<template>
  <div>
    <japanshoppingMb class="is-mb" />
    <japanshoppingTA  class="is-ta" />
    <japanshoppingPc  class="is-pc" />
    <!-- 
      ="screenWidth > 428"
      v-else-if="428 < screenWidth < 768"
      v-else
     -->
  </div>
</template>

<script>
import japanshoppingPc from '@/views_pc/japanshopping/index3'
import japanshoppingMb from '@/views_mb/japanshopping/index3'
import japanshoppingTA from '@/views_pc/japanshopping/index4'
export default {
  components: { japanshoppingPc, japanshoppingMb, japanshoppingTA },
  data() {
    return {
      screenWidth: document.body.clientWidth
    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  },
  watch: {
    screenWidth(val) {
      this.screenWidth = val
      // console.log(val)
    }
  },
  methods: {}
}
</script>
<style lang='scss' scoped>

// @media screen and (min-width: 769px) {
  .is-pc {
  display: block;
  }
  .is-mb {
    display: none;
  }
  .is-ta{
    display: none;
  }
// }
@media screen and (max-width: 428px) {
  .is-pc {
    display: none;
  }
  .is-mb {
    display: block;
  }
  .is-ta{
    display: none;
  }
}
@media screen and (min-width: 429px) and (max-width: 768px) {
  .is-pc {
    display: none;
  }
  .is-mb {
    display: none;
  }
  .is-ta{
    display: block;
  }
}
</style>
